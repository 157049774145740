import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "College Information Videos"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "college-information-videos"
    }}>{`College Information Videos`}</h1>
    <div className="use-grid" />
    <p><div parentName="p" {...{
        "className": "gatsby-resp-iframe-wrapper",
        "style": {
          "paddingBottom": "56.42857142857143%",
          "position": "relative",
          "height": "0",
          "overflow": "hidden"
        }
      }}>{` `}<div parentName="div" {...{
          "className": "embedVideo-container"
        }}>{` `}<iframe parentName="div" {...{
            "title": "",
            "src": "https://www.youtube.com/embed/PSyR-CQIgAU?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0",
              "position": "absolute",
              "top": "0",
              "left": "0",
              "width": "100%",
              "height": "100%"
            },
            "allowFullScreen": true
          }}></iframe>{` `}</div>{` `}</div>{` `}<div parentName="p" {...{
        "className": "gatsby-resp-iframe-wrapper",
        "style": {
          "paddingBottom": "56.42857142857143%",
          "position": "relative",
          "height": "0",
          "overflow": "hidden"
        }
      }}>{` `}<div parentName="div" {...{
          "className": "embedVideo-container"
        }}>{` `}<iframe parentName="div" {...{
            "title": "",
            "src": "https://www.youtube.com/embed/F1y8Sj6rTI4?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0",
              "position": "absolute",
              "top": "0",
              "left": "0",
              "width": "100%",
              "height": "100%"
            },
            "allowFullScreen": true
          }}></iframe>{` `}</div>{` `}</div></p>
    <div className="use-grid" />
    <p><div parentName="p" {...{
        "className": "gatsby-resp-iframe-wrapper",
        "style": {
          "paddingBottom": "56.42857142857143%",
          "position": "relative",
          "height": "0",
          "overflow": "hidden"
        }
      }}>{` `}<div parentName="div" {...{
          "className": "embedVideo-container"
        }}>{` `}<iframe parentName="div" {...{
            "title": "",
            "src": "https://www.youtube.com/embed/FXVcfhcbYuw?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0",
              "position": "absolute",
              "top": "0",
              "left": "0",
              "width": "100%",
              "height": "100%"
            },
            "allowFullScreen": true
          }}></iframe>{` `}</div>{` `}</div>{` `}<div parentName="p" {...{
        "className": "gatsby-resp-iframe-wrapper",
        "style": {
          "paddingBottom": "56.42857142857143%",
          "position": "relative",
          "height": "0",
          "overflow": "hidden"
        }
      }}>{` `}<div parentName="div" {...{
          "className": "embedVideo-container"
        }}>{` `}<iframe parentName="div" {...{
            "title": "",
            "src": "https://www.youtube.com/embed/DckwBq3yz3s?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0",
              "position": "absolute",
              "top": "0",
              "left": "0",
              "width": "100%",
              "height": "100%"
            },
            "allowFullScreen": true
          }}></iframe>{` `}</div>{` `}</div></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      